import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#F08B71',
      light: '#f3a28d',
      dark: '#a8614f'
    },
    secondary: {
      main: '#FD7F2C'
    },
    text: {
      primary: '#484848',
      secondary: '#757575',
      disabled: '#BDBDBD'
    },
    divider: '#eee',
    background: {
      default: '#fafafa',
      card: '#FFFFFF',
      appBar: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: [
      'Helvetica',
      'Arial',
      'ヒラギノ角ゴシック',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 0,
        boxShadow: 'none',
        backgroundColor: '#FFFFFF'
      }
    }
  }
});
